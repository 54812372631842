define("fitbit-site-ui/metrics-adapters/one-trust-cookies-consent", ["exports", "ember-metrics/metrics-adapters/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function generateWebappsCdnUrl(env, filePath) {
    var DOMAINS = {
      int: 'fitbit-int',
      stage: 'fitbit-stage',
      prod: 'fitbit'
    };
    var CHANNELS = {
      int: 'qa2',
      stage: 'qa1',
      prod: 'prod'
    }; // fallback to prod by default

    var domain = DOMAINS[env] || DOMAINS.prod;
    var channelName = CHANNELS[env] || CHANNELS.prod;
    var scriptUrl = "https://corporate-webapps.".concat(domain, ".com/corporate-web-libs/channels/").concat(channelName, "/").concat(filePath);
    return scriptUrl;
  }

  var _default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'one-trust-cookies-consent';
    },
    // required
    init: function init() {
      var _EmberGet = Ember.get(this, 'config'),
          environment = _EmberGet.environment,
          appId = _EmberGet.appId;

      var clientScriptTag = this._createScriptTag();

      clientScriptTag.setAttribute('src', generateWebappsCdnUrl(environment, "oneTrust_production/consent/".concat(appId, "/OtAutoBlock.js")));
      clientScriptTag.setAttribute('integrity', 'sha256-+7z7ToCbV9KjMecylPgbzuWmsJYt00/hukBnfb2pItg=');
      clientScriptTag.setAttribute('crossorigin', 'anonymous');
      document.head.appendChild(clientScriptTag);

      var sdkScriptTag = this._createScriptTag();

      sdkScriptTag.setAttribute('src', generateWebappsCdnUrl(environment, 'oneTrust_production/scripttemplates/otSDKStub.js'));
      sdkScriptTag.setAttribute('integrity', 'sha256-2F5Ny1LOcUxxNuuVoydlMlIFpKq9tRkyvZAkxAC+Zl0=');
      sdkScriptTag.setAttribute('crossorigin', 'anonymous');
      sdkScriptTag.dataset.domainScript = appId;
      document.head.appendChild(sdkScriptTag); // Hide onetrust-pc-dark-filter background after closing window.

      this.addCloseWindowHandler();
    },
    // required
    willDestroy: function willDestroy() {
      document.removeEventListener('click', this.closeWindowHandler);
    },
    _createScriptTag: function _createScriptTag() {
      var scriptEl = document.createElement('script');
      scriptEl.type = 'text/javascript';
      return scriptEl;
    },
    addCloseWindowHandler: function addCloseWindowHandler() {
      document.addEventListener('click', function (event) {
        if (event.target.id === 'close-pc-btn-handler') {
          if ( // Case: There is no cookie banner behind the one trust setting window.
          document.getElementById('onetrust-banner-sdk').style.display === 'none') {
            setTimeout(function () {
              var trustPcFilter = document.querySelector('.onetrust-pc-dark-filter.ot-fade-in');
              trustPcFilter.style.display = 'none';
            }, 400);
          }
        }
      });
    }
  });

  _exports.default = _default;
});