define("fitbit-site-ui/routes/family/parent-approval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    deepLinkWithBackup: Ember.inject.service('deep-link-with-backup'),
    constants: Ember.inject.service(),
    // for testing purposes
    _setWindowLocation: function _setWindowLocation(url) {
      window.location = url;
    },
    model: function model(params) {
      var _this = this;

      var fitbitSupportArticleURL = this.get('constants.FITBIT_SUPPORT_ARTICLE_URL');
      var deepLinkURL = "fitbit://family/".concat(params.approvalType, "/").concat(params.myChildId, "/").concat(params.otherUserId);
      var openInAppPromise = this.deepLinkWithBackup.openInApp(deepLinkURL, {
        timeout: 10
      });
      openInAppPromise.catch(function () {
        // If the Fitbit app is not available (ie. desktop environment), redirect user to the Fitbit support article
        _this._setWindowLocation(fitbitSupportArticleURL);
      });
    }
  });

  _exports.default = _default;
});